<template>
  <div>
    <v-app id="inspire">
      <v-row class="fill-height">
        <v-col lg="3" md="3">
          <v-card flat>
            <v-card-text class="plan-selection">
              <h5><b>Regions</b></h5>
              <v-checkbox
                class="option-checkbox"
                :label="type.label"
                color="primary"
                :value="type.value"
                v-for="(type, index) in regionFilterOptions"
                :key="index"
                multiple
                v-model="regionFilter"
                @change="filterEvent('type', regionFilter)"
                hide-details
              ></v-checkbox>
            </v-card-text>
          </v-card>
          <v-card flat>
            <v-card-text class="plan-selection">
              <h5><b>Status</b></h5>

              <v-checkbox
                class="option-checkbox"
                :label="type.label"
                color="primary"
                :value="type.value"
                v-for="(type, index) in statusFilterOptions"
                :key="index"
                multiple
                v-model="statusFilter"
                @change="filterEvent('type', statusFilter)"
                hide-details
              ></v-checkbox>
            </v-card-text>
          </v-card>
          <v-card flat>
            <v-card-text class="plan-selection">
              <h5><b>Channels</b></h5>

              <v-checkbox
                class="option-checkbox"
                :label="status.label"
                color="primary"
                :value="status.value"
                v-for="(status, index) in channelFilterOptions"
                :key="index"
                v-model="channelFilter"
                multiple
                @change="filterEvent('type', channelFilter)"
                hide-details
              ></v-checkbox>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                Today
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                <b>{{ $refs.calendar.title }}</b>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 days</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="updateRange"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-btn icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-toolbar-title
                    v-html="selectedEvent.name"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <span v-html="selectedEvent.details"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dropdown7 from "@/view/content/dropdown/Dropdown7.vue";
import ApiService from "../../../core/services/api.service";

export default {
  components: {
    Dropdown7,
    ApiService,
  },
  computed: {
    ...mapGetters(["ClientGetters"]),
    client_detail() {
      return this.$store.getters.ClientGetters;
    },
  },
  props: ["clientId"],
  data() {
    return {
      coupons: [],
      mainProps: {
        blank: true,
        blankColor: "#EBE4E4",
        width: 26,
        height: 26,
        class: "mb-2",
      },
      show_calendar: false,

      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      typeFilter: [],
      channelFilter: [],
      regionFilter: [],
      statusFilter: [],
      colors: ["#2374fc"],
      regionFilterOptions: [
        { text: "UAE", label: "UAE", value: "UAE" },
        { text: "SAU", label: "SAU", value: "SAU" },
        { text: "BHR", label: "BHR", value: "BHR" },
        { text: "KWT", label: "KWT", value: "KWT" },
      ],
      channelFilterOptions: [
        {
          text: "INFLUENCERS",
          label: "INFLUENCERS",
          value: "INFLUENCERS",
        },
        {
          text: "AFFILIATES",
          label: "AFFILIATES",
          value: "AFFILIATES",
        },
      ],
      statusFilterOptions: [
        {
          text: "Active",
          label: "Active",
          value: "active",
        },
        {
          text: "In Active",
          label: "In Active",
          value: "inactive",
        },
      ],

      payload: {
        client_id: "",
        // regions: ["KSA"],
        // channels: ["influencers"],
        // statuses: ["active","pending"],
      },
    };
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  async created() {
    await this.getPayouts();
  },

  methods: {
    async getPayouts() {
      this.payload.client_id = this.clientId;
      let response = await ApiService.post(
        `/cancellation-rates/calendar`,
        this.payload
      );
      this.coupons = response.data.data;
      this.events = this.mapEvents(this.coupons);
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      this.events = this.mapEvents(this.coupons);
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    mapEvents(coupons) {
      return coupons.map((x) => {
        return {
          name: `${x.name ? x.name : ""}`,
          start: new Date(x.from_date),
          end: new Date(x.to_date ? x.to_date : x.from_date),
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: false,
        };
      });
    },

    filterEvent(field, model) {
      let result = this.coupons.filter((data) => {
        if (
          (this.typeFilter.length == 0 ||
            this.typeFilter.indexOf(data["type"]) > -1) &&
          (this.regionFilter.length == 0 ||
            this.regionFilter.some((element) =>
              data["regions"].includes(element)
            )) &&
          (this.channelFilter.length == 0 ||
            this.channelFilter.some((element) =>
              data["channels"].includes(element)
            )) &&
          (this.statusFilter.length == 0 ||
            this.statusFilter.some((element) =>
              data["status"].includes(element)
            ))
        ) {
          return true;
        } else {
          return false;
        }
      });
      this.events = this.mapEvents(result);
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.v-calendar {
  .v-event {
    text-align: center;
  }
}
.tab {
  margin-bottom: 10%;
  .title {
    font-weight: 600;
    margin-bottom: 1%;
  }
  .box {
    padding: 1.5rem;
    box-shadow: 0px 0px 10px 1px #d0cccc;
    border-radius: 10px;
    margin-top: 1rem;
    div {
      padding-bottom: 1.5rem;
    }
    input {
      margin-right: 1rem;
    }
    span {
      font-weight: 700;
    }
  }
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.plan-selection {
  box-shadow: 0px 0px 5px gainsboro !important;
  margin-top: 20px;
  padding: 1.5rem;
  border-radius: 10px;
  ::v-deep .v-label {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #000 !important;
  }
}
</style>
