<template>
  <div>
    <div class="row mb-10">
      <div class="col-xl-8 pl-0">
        <!-- <img src="media/images/adidas.svg" /> -->
        <img class="logo" :src="client_detail.logo" />
        <h1 class="ml-4 main-header">
          <b>{{ client_detail.name }} Cancellation</b>
        </h1>

        <v-btn
          class="mx-1 ml-4 mr-4 mdi-plus-bg"
          @click="createCancellation"
          fab
          dark
          color="gainsboro"
        >
          <v-icon class="mdi-plus-content">mdi-plus</v-icon>
        </v-btn>
      </div>

      <div class="col-xl-4">
        <div>
          <!-- <b-form-group
            id="fieldset-start-date"
            label-cols-sm="4"
            label-cols-lg="6"
            content-cols-sm
            content-cols-lg="10"
            label-for="date"
          >
            <b-form-datepicker
              id="date"
              v-model="date"
              @context="onSelectStartDate"
              placeholder="1/10/2021"
              locale="en"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            ></b-form-datepicker>
          </b-form-group> -->

          <div style="float: right">
            <label for="check-button">
              <h6 style="padding-top: 3px">
                <b>Switch to Calendar view</b>
              </h6>
            </label>
            <b-form-checkbox
              name="check-button"
              size="lg"
              switch
              v-model="show_calendar"
              class="switch-to-calendar-button"
            >
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </div>

    <!-- ACTIVE CANCELLATION RULES -->
    <div v-if="!show_calendar">
      <h2 style="font-weight: bold; margin-bottom: 20px">
        Active Cancellation Rules
      </h2>
      <div class="row mb-20">
        <div class="col-xl-12">
          <div
            class="
              card card-custom card-stretch
              gutter-b
              card-shadowless
              my-class-shadow
            "
          >
            <div class="card-body py-0">
              <div style="width: 100%">
                <b-table
                  class="ag-theme-material"
                  sticky-header
                  style="width: 100%; margin-top: 20px"
                  hover
                  :fields="cancellationFields"
                  :items="activeCancellations"
                >
                  <!-- A custom formatted header cell for select all checkbox -->
                  <template #head(index)="">
                    <b-form-checkbox
                      @change="selectAllActivePayout"
                      class="custom-control custom-checkbox checkbox-xl"
                      :checked="
                        selectedActiveCancellation.length ===
                          activeCancellations.length
                      "
                    ></b-form-checkbox>
                  </template>

                  <!-- checkbox slot for each row -->
                  <template v-slot:cell(index)="slot">
                    <b-form-checkbox
                      name="selected-items"
                      class="custom-control custom-checkbox checkbox-xl"
                      v-model="selectedActiveCancellation"
                      :value="slot.item"
                    >
                    </b-form-checkbox>
                  </template>

                  <template v-slot:cell(region)="slot">
                    <span
                      v-if="
                        slot.item.regions.length === 4 ||
                          slot.item.regions.length === 0
                      "
                    >
                      ALL
                    </span>
                    <span v-else>
                      {{ slot.item.region }}
                    </span>
                  </template>
                  <template v-slot:cell(RevenueType)="slot">
                    {{ slot.item.ruless[0].revenue_type }}
                  </template>

                  <!-- action slot for each row -->
                  <template v-slot:cell(action)="slot">
                    <b-row>
                      <b-col
                        cols="auto"
                        @click="editCancellation(slot.item._id)"
                      >
                        <b-avatar role="button" src="media/images/edit.png" />
                        <br />
                        <b>EDIT</b>
                      </b-col>
                      <b-col
                        v-b-modal.delete-payout
                        @click="cancellationToDel = slot.item"
                        cols="auto"
                      >
                        <b-avatar role="button" src="media/images/cancel.png" />
                        <br />
                        <b>CANCEL</b>
                      </b-col>
                      <b-col @click="copyCancellation(slot.item)">
                        <b-avatar role="button" src="media/images/copy.png" />
                        <br />
                        <b>COPY</b>
                      </b-col>
                    </b-row>
                  </template>
                </b-table>
                <div
                  class="no-records"
                  v-if="!activeCancellations || !activeCancellations.length"
                >
                  No Records to show
                </div>
                <b-row>
                  <b-col>
                    <b-pagination
                      v-model="active_current_page"
                      pills
                      :total-rows="active_rows"
                      :per-page="active_per_page"
                      class="b-pagiantion-button"
                    ></b-pagination>
                  </b-col>
                  <b-col style="margin-right: 30px">
                    <b-button
                      class="deleteAll-btn"
                      v-b-modal.deleteAll-payout
                      :disabled="!selectedActiveCancellation.length"
                      @click="deleteAllConfirmation('active')"
                      cols="auto"
                      >Delete All</b-button
                    >
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- UPCOMING CANCELLATION RULES -->
      <h2 style="font-weight: bold; margin-bottom: 20px">
        Upcoming Cancellation Rules
      </h2>
      <div class="row mb-20">
        <div class="col-xl-12">
          <div
            class="
              card card-custom card-stretch
              gutter-b
              card-shadowless
              my-class-shadow
            "
          >
            <div class="card-body py-0">
              <div style="width: 100%">
                <b-table
                  class="ag-theme-material"
                  sticky-header
                  style="width: 100%; margin-top: 20px"
                  hover
                  :fields="cancellationFields"
                  :items="upcomingCancellation"
                >
                  <!-- A custom formatted header cell for select all checkbox -->
                  <template #head(index)="">
                    <b-form-checkbox
                      @change="selectAllUpcomingPayout"
                      class="custom-control custom-checkbox checkbox-xl"
                      :checked="
                        selectedUpcomingCancellation.length ===
                          upcomingCancellation.length
                      "
                    ></b-form-checkbox>
                  </template>

                  <!-- checkbox slot for each row -->
                  <template v-slot:cell(index)="slot">
                    <b-form-checkbox
                      name="selected-items"
                      class="custom-control custom-checkbox checkbox-xl"
                      v-model="selectedUpcomingCancellation"
                      :value="slot.item"
                    >
                    </b-form-checkbox>
                  </template>
                  <template v-slot:cell(region)="slot">
                    <span
                      v-if="
                        slot.item.regions.length === 4 ||
                          slot.item.regions.length === 0
                      "
                    >
                      ALL
                    </span>
                    <span v-else>
                      {{ slot.item.region }}
                    </span>
                  </template>
                  <!-- action slot for each row -->
                  <template v-slot:cell(action)="slot">
                    <b-row>
                      <b-col
                        cols="auto"
                        @click="editCancellation(slot.item._id)"
                      >
                        <b-avatar role="button" src="media/images/edit.png" />
                        <br />
                        <b>EDIT</b>
                      </b-col>
                      <b-col
                        v-b-modal.delete-payout
                        @click="cancellationToDel = slot.item"
                        cols="auto"
                      >
                        <b-avatar role="button" src="media/images/cancel.png" />
                        <br />
                        <b>CANCEL</b>
                      </b-col>
                      <b-col @click="copyCancellation(slot.item)">
                        <b-avatar role="button" src="media/images/copy.png" />
                        <br />
                        <b>COPY</b>
                      </b-col>
                    </b-row>
                  </template>
                </b-table>
                <div
                  class="no-records"
                  v-if="!upcomingCancellation || !upcomingCancellation.length"
                >
                  No Records to show
                </div>
                <b-row>
                  <b-col>
                    <b-pagination
                      v-model="upcoming_current_page"
                      pills
                      :total-rows="upcoming_rows"
                      :per-page="upcoming_per_page"
                      class="b-pagiantion-button"
                    ></b-pagination>
                  </b-col>
                  <b-col style="margin-right: 30px">
                    <b-button
                      class="deleteAll-btn"
                      v-b-modal.deleteAll-payout
                      :disabled="!selectedUpcomingCancellation.length"
                      @click="deleteAllConfirmation('upcoming')"
                      cols="auto"
                      >Delete All</b-button
                    >
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- PAST CANCELLATION RULES -->
      <h2 style="font-weight: bold; margin-bottom: 20px">
        Past Cancellation Rules
      </h2>
      <div class="row mb-20">
        <div class="col-xl-12">
          <div
            class="
              card card-custom card-stretch
              gutter-b
              card-shadowless
              my-class-shadow
            "
          >
            <div class="card-body py-0">
              <div style="width: 100%">
                <b-table
                  class="ag-theme-material"
                  sticky-header
                  style="width: 100%; margin-top: 20px"
                  hover
                  :fields="cancellationFields"
                  :items="pastCancellations"
                >
                  <!-- A custom formatted header cell for select all checkbox -->
                  <template #head(index)="">
                    <b-form-checkbox
                      @change="selectAllPastPayout"
                      class="custom-control custom-checkbox checkbox-xl"
                      :checked="
                        selectedPastCancellation.length ===
                          pastCancellations.length
                      "
                    ></b-form-checkbox>
                  </template>

                  <!-- checkbox slot for each row -->
                  <template v-slot:cell(index)="slot">
                    <b-form-checkbox
                      name="selected-items"
                      class="custom-control custom-checkbox checkbox-xl"
                      v-model="selectedPastCancellation"
                      :value="slot.item"
                    >
                    </b-form-checkbox>
                  </template>
                  <template v-slot:cell(region)="slot">
                    <span
                      v-if="
                        slot.item.regions.length === 4 ||
                          slot.item.regions.length === 0
                      "
                    >
                      ALL
                    </span>
                    <span v-else>
                      {{ slot.item.region }}
                    </span>
                  </template>

                  <!-- action slot for each row -->
                  <template v-slot:cell(action)="slot">
                    <b-row>
                      <b-col
                        cols="auto"
                        @click="editCancellation(slot.item._id)"
                      >
                        <b-avatar role="button" src="media/images/edit.png" />
                        <br />
                        <b>EDIT</b>
                      </b-col>
                      <b-col
                        v-b-modal.delete-payout
                        @click="cancellationToDel = slot.item"
                        cols="auto"
                      >
                        <b-avatar role="button" src="media/images/cancel.png" />
                        <br />
                        <b>CANCEL</b>
                      </b-col>
                      <b-col cols="auto" @click="copyCancellation(slot.item)">
                        <b-avatar role="button" src="media/images/copy.png" />
                        <br />
                        <b>COPY</b>
                      </b-col>
                    </b-row>
                  </template>
                </b-table>
              </div>
              <div
                class="no-records"
                v-if="!pastCancellations || !pastCancellations.length"
              >
                No Records to show
              </div>
              <b-row>
                <b-col>
                  <b-pagination
                    v-model="past_current_page"
                    pills
                    :total-rows="past_rows"
                    :per-page="past_per_page"
                    class="b-pagiantion-button"
                  ></b-pagination>
                </b-col>
                <b-col style="margin-right: 30px">
                  <b-button
                    class="deleteAll-btn"
                    v-b-modal.deleteAll-payout
                    :disabled="!selectedPastCancellation.length"
                    @click="deleteAllConfirmation('past')"
                    cols="auto"
                    >Delete All</b-button
                  >
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CancellationCalendar v-if="show_calendar" :clientId="$route.params.id">
    </CancellationCalendar>
    <b-modal
      id="delete-payout"
      :title="'Confirmation for Cancellation ' + cancellationToDel.name"
      ok-variant="danger"
      ok-title="Remove"
      @ok="del"
      size="sm"
      centered
    >
      <h5>Are you sure you want to delete this cancellation?</h5>
    </b-modal>
    <b-modal
      id="deleteAll-payout"
      :title="'Confirmation for Cancellation '"
      ok-variant="danger"
      ok-title="Remove"
      @ok="delAll"
      size="sm"
      centered
    >
      <h5>Are you sure you want to delete all following cancellation rules?</h5>
      <ul style="margin-left: 20px" v-if="findPayout == 'active'">
        <li v-for="(item, i) in selectedActiveCancellation" v-bind:key="i">
          {{ item.name }}
        </li>
      </ul>
      <ul style="margin-left: 20px" v-if="findPayout == 'upcoming'">
        <li v-for="(item, i) in selectedUpcomingCancellation" v-bind:key="i">
          {{ item.name }}
        </li>
      </ul>
      <ul style="margin-left: 20px" v-if="findPayout == 'past'">
        <li v-for="(item, i) in selectedPastCancellation" v-bind:key="i">
          {{ item.name }}
        </li>
      </ul>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../../core/services/api.service";
import Dropdown7 from "@/view/content/dropdown/Dropdown7.vue";
import CancellationCalendar from "@/view/pages/cancellation-home/cancellation-calendar.vue";
export default {
  components: {
    Dropdown7,
    CancellationCalendar,
  },
  computed: {
    ...mapGetters(["ClientGetters"]),
    client_detail() {
      return this.$store.getters.ClientGetters;
    },
  },
  props: ["cancellations"],
  data() {
    return {
      date: new Date().toISOString().substring(0, 7),
      pagination: [],
      searchQuery: "",
      maxPageNumbers: 10,
      selectedActiveCancellation: [],
      selectedPastCancellation: [],
      selectedUpcomingCancellation: [],
      currentRowIndex: -1,
      cancellationFields: [
        {
          key: "index",
          label: "",
        },
        "name",
        "rate",
        "RevenueType",
        "channels",
        "region",
        "rules",
        "dates",
        "action",
      ],

      activeCancellations: [],
      upcomingCancellation: [],
      pastCancellations: [],

      cancellationPayload: {},
      cancellationToDel: {},
      findPayout: "",

      show_calendar: false,
      active_current_page: 1,
      active_per_page: 5,
      active_rows: 0,
      past_current_page: 1,
      past_per_page: 5,
      past_rows: 0,
      upcoming_current_page: 1,
      upcoming_per_page: 5,
      upcoming_rows: 0,
    };
  },
  mounted() {
    // if user refresh this page directly so we've to hit api again to get client details.
    if (!this.client_detail || !this.client_detail.length) {
      this.$store.dispatch("CLIENT_ACTION", this.$route.params.id);
    }
    this.cancellationPayload = {
      client_id: this.$route.params.id,
      month: this.date.split("-")[1],
      year: this.date.split("-")[0],
    };
    localStorage.removeItem("copyCancellation"), this.getActiveCancellations();
    this.getUpcomingCancellations();
    this.getPastCancellations();
  },
  methods: {
    selectAllActivePayout() {
      if (
        this.selectedActiveCancellation.length ===
        this.activeCancellations.length
      ) {
        this.selectedActiveCancellation = [];
      } else {
        this.selectedActiveCancellation = this.activeCancellations.slice();
      }
    },
    selectAllPastPayout() {
      if (
        this.selectedPastCancellation.length === this.pastCancellations.length
      ) {
        this.selectedPastCancellation = [];
      } else {
        this.selectedPastCancellation = this.pastCancellations.slice();
      }
    },
    selectAllUpcomingPayout() {
      if (
        this.selectedUpcomingCancellation.length ===
        this.upcomingCancellation.length
      ) {
        this.selectedUpcomingCancellation = [];
      } else {
        this.selectedUpcomingCancellation = this.upcomingCancellation.slice();
      }
    },
    createCancellation() {
      this.$router.push({
        name: "create-cancellation",
        params: { id: this.$route.params.id },
      });
    },
    formatRules(payload) {
      let res = [];
      if (payload.is_channel) {
        res.push("Channels");
      }
      if (payload.is_region) {
        res.push("Regions");
      }
      if (payload.is_coupon_series) {
        res.push(" Coupons");
      }
      if (payload.is_revenue) {
        res.push(" RevSlab");
      }
      return res.join(",");
    },

    getActiveCancellations() {
      ApiService.post(
        `cancellation-rates/active-cancellation-rules?page=${this.active_current_page}`,
        this.cancellationPayload
      )
        .then((response) => {
          console.log(response.data.data);
          this.activeCancellations = response.data.data.map((res) => {
            let options = { day: "numeric", month: "short", year: "numeric" };
            let from_date = new Date(res.from_date);
            let to_date = res.to_date ? new Date(res.to_date) : "";
            from_date = from_date.toLocaleDateString("en-US", options);
            to_date = to_date
              ? to_date.toLocaleDateString("en-US", options)
              : "";

            // if (res.regions.length === 4 || res.regions.length === 0) {
            //   res.regions = ["ALL"];
            // }
            return {
              ...res,
              dates: `${from_date} ${
                to_date && res.is_end_date ? "-" + to_date : "- Current"
              }`,
              region: res.regions.join(),
              rules: this.formatRules(res),
              ruless: res.rules,
              rate: res.rules.length && res.rules.map((x) => x.rate).join(),
              channels:
                res.channels && Array.isArray(res.channels)
                  ? res.channels.join()
                  : res.channels,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUpcomingCancellations() {
      ApiService.post(
        `/cancellation-rates/upcoming-cancellation-rules?page=${this.upcoming_current_page}`,
        this.cancellationPayload
      )
        .then((response) => {
          this.upcomingCancellation = response.data.data.map((res) => {
            let options = { day: "numeric", month: "short", year: "numeric" };
            let from_date = new Date(res.from_date);
            let to_date = res.to_date ? new Date(res.to_date) : "";
            from_date = from_date.toLocaleDateString("en-US", options);
            to_date = to_date
              ? to_date.toLocaleDateString("en-US", options)
              : "";

            // if (res.regions.length === 4 || res.regions.length === 0) {
            //   res.regions = ["ALL"];
            // }
            return {
              ...res,
              dates: `${from_date} ${
                to_date && res.is_end_date ? "-" + to_date : "- Current"
              }`,
              region: res.regions.join(),
              rules: this.formatRules(res),
              rate: res.rules.length && res.rules.map((x) => x.rate).join(),
              channels:
                res.channels && Array.isArray(res.channels)
                  ? res.channels.join()
                  : res.channels,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPastCancellations() {
      ApiService.post(
        `/cancellation-rates/past-cancellation-rules?page=${this.past_current_page}`,
        this.cancellationPayload
      )
        .then((response) => {
          this.pastCancellations = response.data.data.map((res) => {
            let options = { day: "numeric", month: "short", year: "numeric" };
            let from_date = new Date(res.from_date);
            let to_date = res.to_date ? new Date(res.to_date) : "";
            from_date = from_date.toLocaleDateString("en-US", options);
            to_date = to_date
              ? to_date.toLocaleDateString("en-US", options)
              : "";
            // if (res.regions.length === 4 || res.regions.length === 0) {
            //   res.regions = ["ALL"];
            // }
            return {
              ...res,
              dates: `${from_date} ${
                to_date && res.is_end_date ? "-" + to_date : "- Current"
              }`,
              region: res.regions.join(),
              rules: this.formatRules(res),
              rate: res.rules.length && res.rules.map((x) => x.rate).join(),
              channels:
                res.channels && Array.isArray(res.channels)
                  ? res.channels.join()
                  : res.channels,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async del() {
      if (!this.cancellationToDel) {
        return;
      }
      try {
        await ApiService.post("/cancellation-rates/delete", {
          cancellation_rate_ids: [this.cancellationToDel._id],
        });
        this.getActiveCancellations();
        this.getUpcomingCancellations();
        this.getPastCancellations();
      } catch (err) {
        console.log(err);
      }
    },

    editCancellation(cancellationId) {
      this.$router.push({
        name: "edit-cancellation",
        params: { id: cancellationId },
        query: { name: this.client_detail.name },
      });
    },
    copyCancellation(cancellation) {
      localStorage.setItem("copyCancellation", cancellation._id),
        this.$router.push({
          name: "copy-cancellation",
          params: { id: cancellation._id },
          query: { name: this.client_detail.name },
        });
    },

    async delAll() {
      if (
        !this.selectedActiveCancellation &&
        !this.selectedUpcomingCancellation &&
        !this.selectedPastCancellation
      ) {
        return;
      }
      let allDeleteCancellation = [];
      if (this.findPayout == "active") {
        this.selectedActiveCancellation.map((item, index) => {
          allDeleteCancellation.push(item._id);
        });
      }
      if (this.findPayout == "upcoming") {
        this.selectedUpcomingCancellation.map((item, index) => {
          allDeleteCancellation.push(item._id);
        });
      }
      if (this.findPayout == "past") {
        this.selectedPastCancellation.map((item, index) => {
          allDeleteCancellation.push(item._id);
        });
      }
      try {
        await ApiService.post("/cancellation-rates/delete", {
          cancellation_rate_ids: allDeleteCancellation,
        });
        if (this.findPayout == "active") {
          this.getActiveCancellations();
        }
        if (this.findPayout == "upcoming") {
          this.getUpcomingCancellations();
        }
        if (this.findPayout == "past") {
          this.getPastCancellations();
        }

        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    },
    deleteAllConfirmation(event) {
      this.findPayout = event;
    },
    onSelectStartDate(ctx) {
      this.date = ctx.activeYMD;
    },
  },
  watch: {
    date: {
      handler: function() {
        this.cancellationPayload = {
          client_id: this.$route.params.id,
          month: this.date.split("-")[1],
          year: this.date.split("-")[0],
        };
        // this.getActiveCancellations();
        // this.getUpcomingCancellations();
        // this.getPastCancellations();
      },
    },
    active_current_page: {
      handler: function() {
        // if (this.totalRecords <= 0) {
        //   return;
        // }
        this.getActiveCancellations();
      },
    },
    past_current_page: {
      handler: function() {
        // if (this.totalRecords <= 0) {
        //   return;
        // }
        this.getPastCancellations();
      },
    },
    upcoming_current_page: {
      handler: function() {
        // if (this.totalRecords <= 0) {
        //   return;
        // }
        this.getUpcomingCancellations();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .table {
  height: calc(100% - 25px);
  th,
  td {
    vertical-align: middle;
    padding: 10px;
  }
  thead {
    color: #000;
    th {
      text-transform: uppercase;
      padding-bottom: 15px;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-input:checked ~ .custom-control-label::after {
    box-shadow: 0 0 0 2px rgba(32, 33, 36, 0.122) !important;
    border: none !important;
  }

  .checkbox-xl .custom-control-label::before,
  .checkbox-xl .custom-control-label::after {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    opacity: 1;
    font-weight: 400;
    border: 2px solid #0000008a;
  }
  .b-avatar {
    height: 25px;
    width: 25px;
  }
}
.logo {
  height: 85px;
  width: 80px;
  border-radius: 20px;
  border: 2px solid white;
  box-shadow: gainsboro 0px 0px 5px;
}
.card.card-custom.card-stretch.gutter-b {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.card.card-custom > .card-body {
  padding: 2rem 1em 2rem 2.29rem;
}
.card {
  border-radius: 20px;
}

/* Scroll bar style */
.b-table-sticky-header {
  overflow-y: auto;
  max-height: 500px;
  &::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff0d9;
    outline: 1px solid #fff0d9;
  }
}
.no-records {
  height: 400px;
  text-align: center;
  padding: 50px;
}
.deleteAll-btn {
  background: rgb(243, 14, 83);
  float: right !important;
  color: white;
  width: 120px !important;
  margin-bottom: 20px !important;
}
</style>
